import * as Tone from 'tone'
import progressions from './progressions'
import { init } from './utils/three-helpers'
import fx from './effects'
import raf from './utils/raf'
import { Mesh, MeshBasicMaterial, Object3D, SphereBufferGeometry, Color } from 'three'
import Pluck from './Pluck'
import { lerp, stringToHex } from './utils/helpers'
import MidiWriter from 'midi-writer-js'
import * as tome from 'chromotome'
import Clr from 'color'

export default function blockstyle ({
  attributesRef, canvasRef, block,
  width, height,
  mod1, mod2, mod3, mod4, color3, color1, color2,
  setMidi, canPlay
}, { shuffleBag }) {

  // const arpeggio = block.number.toString().replace(/0/g, '').length <= 1
  const arpeggio = [...new Set(block.number.toString().split(''))].length === 1

  function random () { return shuffleBag.current.random() }
  const canvas = canvasRef.current

  const palette = tome.get(tome.getNames()[block.number % (tome.getNames().length - 1)])
  const colors = [palette.background, ...palette.colors].filter(c => c)

  const _color3 = new Clr(color3).mix(new Clr(colors.shift()), mod4, {
    space: 'lch',
    outputSpace: 'srgb'
  }).hex()

  const _color1 = new Clr(color1).mix(new Clr(colors.shift()), mod4, {
    space: 'lch',
    outputSpace: 'srgb'
  }).hex()

  const _color2 = new Clr(color2).mix(new Clr(colors.shift()) || _color1, mod4, {
    space: 'lch',
    outputSpace: 'srgb'
  }).hex()

  if (!canPlay.current) {
    canvas.style.cursor = 'pointer'
    const enableAudio = () => {
      Tone.start().then(() => {
        canPlay.current = true
        canvas.style.cursor = 'default'
      })
    }
    canvas.addEventListener('click', enableAudio, { once: true })
    canvas.addEventListener('touchstart', enableAudio, { once: true })
    canvas.addEventListener('touchend', enableAudio, { once: true })
  }

  const startAudio = () => {
    if (Tone.context.rawContext.state !== 'running') {
      Tone.context.rawContext.resume()
      Tone.context.resume()
      Tone.Transport.start()
      Tone.start()
    }
  }

  document.addEventListener('click', startAudio, { once: true })
  document.addEventListener('touchstart', startAudio, { once: true })
  document.addEventListener('touchend', startAudio, { once: true })

  const bpm = Math.round(lerp(40, 180, mod1))
  const tempo = 240000 / (bpm * 2)
  const phaseOffset = 1 - mod2
  const chordIndex = Math.round(random() * (progressions.length - 1))
  const chords = progressions[chordIndex]
  const numberOfBoxes = arpeggio ? 1 : chords[0].length

  // * RARE FEATURES
  const quality = random() > 0.99 ? (random() > 0.75 ? 'Extreme' : 'Broken') : 'Lo-Fi'
  const totemize = random() > 0.95
  // *

  const pixelSize = { 'Extreme': width / 16, 'Broken': width / 32, 'Lo-Fi': width / 96 }[quality]
  const globalScale = lerp(0.6, arpeggio ? 2 : 1, random())

  const adsr = [
    random() > 0.5 ? 0.001 : random() / 5,
    random()
  ]

  let adsrType
  if (adsr[0] < 0.02) {
    if (adsr[1] < 0.2) {
      adsrType = 'Percussions'
    } else {
      adsrType = 'Keys'
    }
  } else {
    if (adsr[1] < 0.7 && adsr[0] < 0.05) {
      adsrType = 'Brass'
    } else {
      adsrType = 'Pads'
    }
  }

  attributesRef.current = { // https://docs.opensea.io/docs/metadata-standards
    attributes: [
      {
        trait_type: 'Chord Progression',
        value: 'ΔΣΨΞΩΦΘΛΠΓΑΒΕΖΗΙΚΜΝΟΡΤΥΧ'.split('')[chordIndex]
      },
      {
        trait_type: 'Quality',
        value: quality
      },
      {
        trait_type: 'Arrangement',
        value: totemize ? 'Totem' : 'Void'
      },
      {
        trait_type: 'Instrument Family',
        value: adsrType
      },
      {
        trait_type: 'Sequencing',
        value: arpeggio ? 'Arpeggiator' : 'Regular'
      }
    ]
  }

  let chord = 0
  let note = 0
  function nextNote () { note = (note + 1) % chords[0].length }
  function nextChord () {
    chord = (chord + 1) % chords.length
    note = 0
  }

  const { camera, renderer, scene } = init(canvas, width, height)
  renderer.pixelRatio = window.devicePixelRatio

  const { composer } = fx({ renderer, scene, camera }, { width, height }, pixelSize)

  const rad = 0.1

  const totemBoxHeight = globalScale * 10 / numberOfBoxes

  camera.position.z = 24
  camera.position.x = 24
  camera.position.y = 10

  const synth = new Tone.PolySynth().toDestination()
  synth.set({
    envelope: {
      attack: adsr[0],
      sustain: adsr[1]
    }
  });

  const transpose = Math.round(random() * 12 - 4)

  function fibonacciSphere (index, samples) {
    if (samples === 1) return { x: 0, y: 0, z: 0 }
    if (totemize) return {
      x: 0,
      y: (index - (samples - 1) / 2) * totemBoxHeight / 2,
      z: 0
    }

    const phi = Math.PI * (3 - Math.sqrt(5))
    const y = 1 - (index / (samples - 1)) * 2
    const radius = Math.sqrt(1 - y * y)

    return {
      x: Math.cos(phi * index) * radius,
      y,
      z: Math.sin(phi * index) * radius
    }
  }

  const globalObj = new Object3D()

  function createPluckBox () {
    const obj = new Object3D()
    const o = new Object3D()

    const WIDTH = globalScale * (rad * 2 + 4 * random() * (totemize ? 5 / numberOfBoxes : 1))
    const HEIGHT = globalScale * (totemize ? totemBoxHeight : rad * 2 + 4 * random())
    const DEPTH = globalScale * (rad * 2 + 4 * random() * (totemize ? 5 / numberOfBoxes : 1))
  
    const triplet = [
      random() > 0.1 ? 1 : 1.5,
      random() > 0.1 ? 1 : 1.5,
      random() > 0.1 ? 1 : 1.5
    ]

    const pluck = new Pluck({ width: WIDTH, height: HEIGHT, depth: DEPTH }, {
      pos: {
        x: WIDTH * lerp(random(), 0.5, phaseOffset),
        y: HEIGHT * lerp(random(), 0.5, phaseOffset),
        z: DEPTH * lerp(random(), 0.5, phaseOffset)
      },
      vel: {
        x: WIDTH * triplet[0] * 2 ** Math.round(random() ** Math.max(1, numberOfBoxes - 1) * 3) / tempo / 4,
        y: HEIGHT * triplet[1] * 2 ** Math.round(random() ** Math.max(1, numberOfBoxes - 1) * 3) / tempo / 4,
        z: DEPTH * triplet[2] * 2 ** Math.round(random() ** Math.max(1, numberOfBoxes - 1) * 3) / tempo / 4
      },
      rad
    })

    const pluckObj = new SphereBufferGeometry(rad)
    const pluckMat = new MeshBasicMaterial({ color: stringToHex(_color1) })
    const pluckMesh = new Mesh(pluckObj, pluckMat)
  
    const { box, boxMat } = pluck.createBox()

    pluckMat.color.set(new Color(stringToHex(_color1)))
    boxMat.color.set(new Color(stringToHex(_color1)))
  
    box.position.set(WIDTH / 2, HEIGHT / 2, DEPTH / 2)
    o.position.set(WIDTH / -2, HEIGHT / -2, DEPTH / -2)
  
    o.add(box, pluckMesh)
    obj.add(o)
  
    return { pluck, obj, pluckMesh, pluckMat, boxMat }
  }

  const plucks = []
  for (let i = 0; i < numberOfBoxes; i++) {
    const { pluck, obj, pluckMesh, pluckMat, boxMat } = createPluckBox()
    globalObj.add(obj)

    obj.position.x = fibonacciSphere(i, numberOfBoxes).x * 3
    obj.position.y = fibonacciSphere(i, numberOfBoxes).y * 3
    obj.position.z = fibonacciSphere(i, numberOfBoxes).z * 3

    plucks.push({ pluck, pluckMesh, pluckMat, boxMat, obj, dna: random() })
  }

  scene.add(globalObj)

  let oldTime = 0 
  raf.subscribe((time) => {
    scene.background = new Color(stringToHex(_color3))

    const s = mod3 + 0.5
    globalObj.scale.set(s, s, s)

    const beat = Math.floor(time / 1000 / 60 * bpm * 2)
    const oldBeat = Math.floor(oldTime / 1000 / 60 * bpm * 2)
    if (beat % 8 === 0 && oldBeat % 8 > 0) nextChord()

    plucks.forEach(({ pluck, pluckMesh, pluckMat, boxMat, obj, dna }, i) => {
      const pos = pluck.getPositionAt(time)
      pluckMesh.position.set(pos.x, pos.y, pos.z)

      obj.rotation.y = (time + 100) * Math.sin(dna * 20) * (totemize ? 0.0003 : 0.0001)
      
      if (!totemize) {
        obj.rotation.x = (time + 100) * (dna - 0.5) * 0.0001
        obj.rotation.z = (time + 100) * Math.cos(dna * 20) * 0.0001
        obj.position.y = fibonacciSphere(i, numberOfBoxes).y * 3 + Math.sin(time / 3000 * dna + dna * 2) / 2
      }

      if (
        Math.sign(pluck.getVelocityAt(oldTime).x) !== Math.sign(pluck.getVelocityAt(time).x) ||
        Math.sign(pluck.getVelocityAt(oldTime).y) !== Math.sign(pluck.getVelocityAt(time).y) ||
        Math.sign(pluck.getVelocityAt(oldTime).z) !== Math.sign(pluck.getVelocityAt(time).z)
      ) {
        let thisNote = i
        if (arpeggio) {
          thisNote = note
          nextNote()
        }
        if (Tone.context.state === 'running' && canPlay.current)
          synth.triggerAttackRelease(Tone.Frequency(chords[chord][thisNote]).transpose(transpose), '8n', undefined, i === 0 ? 1 : 0.5)

        pluckMat.color.set(new Color(stringToHex(_color2)))
        boxMat.color.set(new Color(stringToHex(_color2)))
        window.setTimeout(() => {
          pluckMat.color.set(new Color(stringToHex(_color1)))
          boxMat.color.set(new Color(stringToHex(_color1)))
        }, 150)
      }
    })

    composer.render()

    oldTime = time
  })

  setMidi(createMidi())

  function createMidi () {
    const track = new MidiWriter.Track()
    track.setTempo(bpm)
    track.setTimeSignature(4, 4)
    track.addInstrumentName('Pad 3')
    track.addTrackName(`Ethereal ${arpeggio ? 'Arpeggio' : 'Melody'} from block ${block.number.toString()}`)

    const beatsPerBar = 4
    const allNotes = []
    plucks.forEach(({ pluck }, i) => {
      [
        { pos: pluck.pos.x, vel: pluck.vel.x, size: pluck.boxWidth },
        { pos: pluck.pos.y, vel: pluck.vel.y, size: pluck.boxHeight },
        { pos: pluck.pos.z, vel: pluck.vel.z, size: pluck.boxDepth }
      ].forEach(({ pos, vel, size }) => {
        const hitsPerBar = Math.abs(vel) * 2 * tempo / size
        const phase = (vel > 0 ? 1 - (pos / size) : pos / size) * (beatsPerBar / hitsPerBar) * 128
        for (let a = 0; a < hitsPerBar * chords.length; a++) {
          allNotes.push({
            chordIndex: Math.floor(a / hitsPerBar),
            noteIndex: i,
            startTick: phase + (a * beatsPerBar / hitsPerBar) * 128
          })
        }
      })
    })

    if (arpeggio) {
      let ticks = []
      let lastChordIndex = 0
      let j = 0
      allNotes
        .sort((a, b) => a.startTick - b.startTick) // triées par startTick
        .filter(({ startTick }) => {
          if (!ticks.includes(Math.round(startTick))) ticks.push(Math.round(startTick))
          return ticks.includes(Math.round(startTick))
        })
        .map(({ chordIndex, startTick }, i) => {
          if (chordIndex !== lastChordIndex) j = 0
          const nt = {
            pitch: Tone.Frequency(chords[chordIndex][j]).transpose(transpose).toNote(),
            duration: '8',
            startTick
          }
          lastChordIndex = chordIndex
          j = (j + 1) % chords[chordIndex].length
          return nt
        })
        .forEach(({ pitch, duration, startTick }) => track.addEvent(new MidiWriter.NoteEvent({ pitch, duration, startTick })))
    } else {
      allNotes.forEach(({ chordIndex, noteIndex, startTick }) => {
        const note = new MidiWriter.NoteEvent({
          pitch: Tone.Frequency(chords[chordIndex][noteIndex]).transpose(transpose).toNote(),
          duration: '8',
          startTick
        })
        track.addEvent(note)
      })
    }

    const write = new MidiWriter.Writer(track)
    return write.dataUri()
  }

  return () => {
    raf.reset()
    Tone.Transport.pause()
  }
}
