import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import raf from './raf'

export function init (canvas, width, height) {
  const scene = new THREE.Scene()
  const camera = new THREE.PerspectiveCamera(20, width / height, 0.1, 100)
  const renderer = new THREE.WebGLRenderer({ canvas, antialias: true })
  const controls = new OrbitControls(camera, renderer.domElement)

  renderer.setSize(width, height)

  controls.enableDamping = true
  controls.enableZoom = false
  controls.enablePan = false
  controls.autoRotate = true

  window.addEventListener('resize', () => {
    camera.aspect = width / height
    camera.updateProjectionMatrix()
    renderer.setSize(width, height)
  })

  raf.subscribe(() => {
    controls.update()
  })

  return {
    scene,
    camera,
    renderer,
    controls
  }
}
