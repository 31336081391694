import React, { useEffect, useRef, useState } from 'react'
import MersenneTwist from 'mersenne-twister'
import blockstyle from './blockstyle/BlockStyle'
import { range } from './blockstyle/utils/helpers'
import * as Tone from 'tone'

const styleMetadata = {
  name: 'Etheral Melodies',
  description: `These musical debris float in the void of the Ethereum chain.

MOD1: BPM (40-180)
MOD2: Pluck starting location randomization
MOD3: Zoom
MOD4: Switch to custom palette

Click to enable sound`,
  image: 'ipfs://QmUKtCAWsUNqtKAgY88wJVbWtvWzp78TF1sP8VxY6uLMjQ',
  creator_name: 'Nèr Arfer',
  options: {
    mod1: range(120, 40, 180, 0, 1), // bpm
    mod2: 1,
    mod3: 0.4,
    mod4: 1,
    color1: '#ffffff',
    color2: '#0000ff',
    color3: '#000000'
  },
}

export { styleMetadata }

const canPlay = { current: false }

const Outer = React.memo(
  ({
    attributesRef, canvasRef, block,
    width, height, handleResize,
    mod1, mod2, mod3, mod4, color3, color1, color2
  }) => {
    const shuffleBag = useRef()
    const seed = parseInt(block.hash.slice(0, 16), 16)
    shuffleBag.current = new MersenneTwist(seed)

    const [displayUI, setDisplayUI] = useState(false)

    const [volumeVal, setVolumeVal] = useState(0.8)

    function setVolume (value) {
      if (value === 0) {
        Tone.Destination.mute = true
      } else {
        Tone.Destination.mute = false
        Tone.Destination.volume.value = Math.log10(value) * 15 - 5
      }
    }

    const [midi, setMidi] = useState('')

    useEffect(() => {
      setVolumeVal(volumeVal)
      return blockstyle({
        attributesRef, canvasRef, block,
        width, height, handleResize,
        mod1, mod2, mod3, mod4, color3, color1, color2,
        setMidi, canPlay
      }, { shuffleBag })
    }, [width, height, canvasRef, block, mod1, mod2, mod3, mod4, color3, color1, color2])

    return (
      <div
        style={{
          width,
          height,
          position: 'relative'
        }}
        onMouseEnter={() => { setDisplayUI(true) }}
        onMouseLeave={() => { setDisplayUI(false) }}
      >
        <span style={{
          position: 'relative',
          display: 'inline-block',
          width,
          height
        }}>
          <canvas
            width={width}
            height={height}
            style={{ width: '100%', height: '100%' }}
            ref={canvasRef}
          />
        </span>

        <a
          style={{
            bottom: 2,
            right: 2,
            position: 'absolute',
            background: 'white none repeat scroll 0% 0%',
            border: '2px solid black',
            padding: '4px 6px',
            color: 'black',
            textDecoration: 'none',
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Monaco, sans-serif',
            opacity: displayUI ? 1 : 0,
            transition: 'opacity 200ms ease 0s',
            userSelect: 'none'
          }}
          href={midi}
          download="etheral-melody.mid"
        >MIDI</a>

        <div
          className="audio"
          style={{
            bottom: 2,
            left: 2,
            position: 'absolute',
            background: 'white none repeat scroll 0% 0%',
            border: '2px solid black',
            padding: '10px',
            color: 'black',
            textDecoration: 'none',
            opacity: displayUI ? 1 : 0,
            transition: 'opacity 200ms ease 0s',
          }}
        >
          <Slider
            state={[volumeVal, setVolumeVal]}
            min={0}
            max={1}
            steps={10}
            onChange={setVolume}
          ></Slider>
        </div>
      </div>
    )
  }
)

const Slider = function (props) {
  const [value, setValue] = props.state

  const handleChange = (val) => {
    props.onChange(parseFloat(val))
  }

  const btns = new Array(props.steps).fill(undefined).map((_, i) =>
    <button
      key={i}
      style={{
        display: 'block',
        opacity: props.steps - i > value * props.steps ? 0.3 : 1,
        backgroundColor: '#000000',
        border: 'none',
        cursor: 'pointer',
        margin: 1,
        padding: 0,
        height: 10,
        width: 10,
        boxSizing: 'border-box'
      }}
      onClick={() => {
        setValue(1 - i / (props.steps - 1))
        handleChange(1 - i / (props.steps - 1))
      }}
    ></button>
  )

  return (
    <div>
      {btns}
    </div>
  )
}

export default Outer
