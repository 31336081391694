export default [
  [
    ['E2', 'E3', 'G3', 'B3', 'F#3'],
    ['E2', 'E3', 'G3', 'B3', 'F#3'],
    ['B2', 'E3', 'G3', 'B3', 'F#3'],
    ['B2', 'E3', 'G3', 'B3', 'F#3'],
    ['C3', 'E3', 'G3', 'B3', 'F#3'],
    ['C3', 'E3', 'G3', 'B3', 'F#3'],
    ['A2', 'E3', 'G3', 'B3', 'F#3'],
    ['A2', 'E3', 'G3', 'B3', 'F#3']
  ], [
    ['D2', 'F3', 'A4', 'C4', 'E4'],
    ['D2', 'F3', 'A4', 'C4', 'E4'],
    ['G2', 'E3', 'A4', 'B4', 'G4'],
    ['G2', 'E3', 'A4', 'B4', 'G4'],
    ['C2', 'C3', 'E4', 'A4', 'B4'],
    ['C2', 'C3', 'E4', 'A4', 'B4'],
    ['C2', 'C3', 'E4', 'G4', 'B4'],
    ['C2', 'C3', 'E4', 'G4', 'B4']
  ], [
    ['A#2', 'F3', 'C#4'],
    ['D#2', 'A#2', 'F#3'],
    ['F2', 'C3', 'A3'],
    ['F#2', 'C#3', 'A#3']
  ], [
    ['A#2', 'F3', 'C#4', 'C#4'],
    ['D#2', 'A#2', 'F#3', 'A#3'],
    ['F2', 'C3', 'A3', 'C4'],
    ['F#2', 'C#3', 'A#3', 'D#4']
  ], [
    ['C3', 'G3', 'D4',     'C4', 'D#4', 'A#4'],
    ['G#2', 'G#3', 'A#3',  'C4', 'D#4', 'A#4'],
    ['F2', 'C3', 'G#3',    'C4', 'D#4', 'G4'],
    ['G#2', 'D#3', 'A#3',  'C4', 'D#4', 'G#4']
  ], [
    ['G#2', 'D#3', 'A#3', 'C4', 'G4'],
    ['G2', 'D3', 'G3', 'B3', 'F4'],
    ['C3', 'G3', 'D4', 'A#3', 'D#4'],
    ['F2', 'C3', 'G3', 'G#3', 'D#4'],
    ['G#2', 'D#3', 'G#3', 'A#3', 'D#4'],
    ['F2', 'C3', 'G#3', 'C4', 'D#4'],
    ['G2', 'D3', 'A3', 'B3', 'D4'],
    ['G2', 'D3', 'A3', 'B3', 'D4']
  ], [
    ['E3', 'G#3', 'B3', 'E4'],
    ['D#3', 'F#3', 'B3', 'D#4'],
    ['E3', 'G#3', 'C#4', 'E4'],
    ['E3', 'A3', 'C#4', 'E4'],
  ], [
    ['F2', 'F3', 'A3', 'C4'],
    ['C3', 'E3', 'G3', 'A3'],
    ['D2', 'D3', 'F3', 'A3'],
    ['D2', 'D3', 'F3', 'A3'],
  ], [
    ['C3', 'E3', 'G3'], // C
    ['A2', 'C3', 'E3'], // a
    ['F2', 'A2', 'C3'], // F
    ['G2', 'B2', 'D3'], // G
  ], [
    ['C3', 'E3', 'G3', 'D4', 'E4'], // C
    ['G2', 'B2', 'D3', 'G3', 'D4'], // G
    ['A2', 'C3', 'E3', 'B3', 'C4'], // a
    ['E2', 'G2', 'B2', 'E3', 'B3'], // e
    ['F2', 'A2', 'C3', 'G3', 'A3'], // F
    ['C2', 'E2', 'G2', 'C3', 'G3'], // C
    ['F2', 'A2', 'C3', 'G3', 'A3'], // F
    ['G2', 'B2', 'D3', 'B3', 'B3'], // G
  ]
]
