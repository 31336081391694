import { BoxBufferGeometry, Mesh, MeshBasicMaterial, Object3D } from 'three'

export default class Pluck {
  constructor ({ width, height, depth }, { pos, vel, rad }) {
    this.boxWidth = width
    this.boxHeight = height
    this.boxDepth = depth

    this.rad = rad
    this.pos = pos
    this.vel = vel
  }

  getPositionAt (t) {
    const calculatePosWithBounces = (pos, vel, boxSize) =>
      this.rad / 2 + Math.abs((pos + vel * t + Math.sign(vel) * (boxSize - this.rad)) % (2 * (boxSize - this.rad)) - Math.sign(vel) * (boxSize - this.rad))

    return {
      x: calculatePosWithBounces(this.pos.x, this.vel.x, this.boxWidth),
      y: calculatePosWithBounces(this.pos.y, this.vel.y, this.boxHeight),
      z: calculatePosWithBounces(this.pos.z, this.vel.z, this.boxDepth)
    }
  }

  getVelocityAt (t) {
    const calculateVelWithBounces = (pos, vel, boxSize) =>
      Math.sign((pos + vel * t + Math.sign(vel) * (boxSize - this.rad)) % (2 * (boxSize - this.rad)) - Math.sign(vel) * (boxSize - this.rad)) * vel

    return {
      x: calculateVelWithBounces(this.pos.x, this.vel.x, this.boxWidth),
      y: calculateVelWithBounces(this.pos.y, this.vel.y, this.boxHeight),
      z: calculateVelWithBounces(this.pos.z, this.vel.z, this.boxDepth)
    }
  }

  createBox () {
    const box = new Object3D()
    const w = 0.7 * this.rad
    const boxMat = new MeshBasicMaterial({ color: 0xffffff })
    const boxMeshes = [
      [
        new BoxBufferGeometry(this.boxWidth, w, w),
        new BoxBufferGeometry(this.boxWidth, w, w),
        new BoxBufferGeometry(this.boxWidth, w, w),
        new BoxBufferGeometry(this.boxWidth, w, w)
      ].map(geo => new Mesh(geo, boxMat)),
      [
        new BoxBufferGeometry(w, this.boxHeight, w),
        new BoxBufferGeometry(w, this.boxHeight, w),
        new BoxBufferGeometry(w, this.boxHeight, w),
        new BoxBufferGeometry(w, this.boxHeight, w)
      ].map(geo => new Mesh(geo, boxMat)),
      [
        new BoxBufferGeometry(w, w, this.boxDepth),
        new BoxBufferGeometry(w, w, this.boxDepth),
        new BoxBufferGeometry(w, w, this.boxDepth),
        new BoxBufferGeometry(w, w, this.boxDepth)
      ].map(geo => new Mesh(geo, boxMat))
    ]

    boxMeshes[0][0].position.set(0, this.boxHeight / 2, this.boxDepth / 2)
    boxMeshes[0][1].position.set(0, -this.boxHeight / 2, this.boxDepth / 2)
    boxMeshes[0][2].position.set(0, this.boxHeight / 2, -this.boxDepth / 2)
    boxMeshes[0][3].position.set(0, -this.boxHeight / 2, -this.boxDepth / 2)

    boxMeshes[1][0].position.set(this.boxWidth / 2, 0, this.boxDepth / 2)
    boxMeshes[1][1].position.set(-this.boxWidth / 2, 0, this.boxDepth / 2)
    boxMeshes[1][2].position.set(this.boxWidth / 2, 0, -this.boxDepth / 2)
    boxMeshes[1][3].position.set(-this.boxWidth / 2, 0, -this.boxDepth / 2)

    boxMeshes[2][0].position.set(this.boxWidth / 2, this.boxHeight / 2, 0)
    boxMeshes[2][1].position.set(this.boxWidth / 2, -this.boxHeight / 2, 0)
    boxMeshes[2][2].position.set(-this.boxWidth / 2, this.boxHeight / 2, 0)
    boxMeshes[2][3].position.set(-this.boxWidth / 2, -this.boxHeight / 2, 0)

    box.add(...boxMeshes.flat())
    return { box, boxMat }
  }
}
